<template>
  <div class="home">
    <p class="par">
      Altijd op de hoogte blijven van leuke acties van een deze hot-spots? Check de website regelmatig voor wat er allemaal te doen is!
    </p>
    <AlleGerechten />
  </div>
</template>

<script>
import AlleGerechten from "@/components/AlleGerechten.vue";

export default {
  name: "Home",
  components: {
    AlleGerechten
  }
};
</script>
