<template>
  <div class="cart-inner">
    <div>
      <button :style="bgColor" @click="$emit('close')" class="close">&times;</button>
      <div class="container">
        <h2 style="text-align: center;">Winkelwagen</h2>
        <div v-if="$store.state.cart.length > 0">
          <div v-for="(product, i) in $store.state.cart" :key="i" class="flex cart-product">
            <div>
              {{ product.product.naam }}
              <small>
                ({{ product.quantity }}
                {{ personen(product.quantity) }})
              </small>
            </div>
            <div class="price ml-auto">€{{ product.product.prijs * product.quantity }}</div>
          </div>
          <div class="totaal flex">
            <strong>Totaal:</strong>
            <div class="ml-auto">€{{ total.toFixed(2) }}</div>
          </div>
        </div>
        <div class="locatie">
          <h3>Afhalen bij *</h3>
          <div
            v-for="(item, i) in gekozenType.locaties"
            :key="i"
            style="padding: 0.5rem 0rem;"
            class="flex ai_c"
          >
            <input type="radio" v-model="afhaalLocatie" :value="item" />
            <div style="margin-left: 0.4rem;">
              {{ item.split(":")[0] }}
              <br />
              <small>{{ item.split(":")[1] }}</small>
            </div>
          </div>
          <div v-if="gekozenType.afhaaltijd_uitschakelen !== true">
            <h3>Afhaaltijd</h3>
            <p class="info-box" v-if="gekozenType.bestel_melding">
              <vue-simple-markdown :source="gekozenType.bestel_melding"></vue-simple-markdown>
            </p>
            <div class="input-item">
              <label>Datum *</label>
              <input type="date" v-model="afhaalDatum" :disabled="disableDate" />
            </div>
            <div class="input-item">
              <label>Tijd *</label>
              <select v-model="afhaalTijd">
                <option
                  v-for="(item, i) in gekozenType.afhaaltijden"
                  :key="i"
                  :value="item"
                >{{ item }}</option>
              </select>
            </div>
          </div>
          <h3>Gegevens</h3>
          <div class="input-item">
            <label>Naam *</label>
            <input type="text" v-model="naam" required />
          </div>
          <div class="input-item">
            <label>Telefoon</label>
            <input type="text" v-model="telefoon" />
          </div>
          <div class="input-item">
            <label>Email *</label>
            <input type="text" v-model="email" required />
          </div>
          <div class="input-item">
            <label>Opmerking</label>
            <textarea
              type="text"
              v-model="opmerking"
              maxlength="200"
              style="width: 300px; max-width: 100%; min-height: 150px;"
            />
          </div>
        </div>
        <div class="checkout-info">
          <img :src="require('@/assets/img/ideal.svg')" style="height: 30px;" />
          <p>
            Je kunt je bestelling veilig afrekenen met iDeal. Je ontvang een
            mailtje met een unieke code. Laat dit mailtje zien bij het afhalen
            van je bestelling. Printen is niet noodzakelijk. Alvast eet
            smakelijk :).
          </p>
        </div>
        <button
          :disabled="submitting"
          class="btn"
          :style="bgColor"
          style="margin-top: 1rem;"
          @click="checkout"
        >
          <div v-if="!submitting">
            Afrekenen
            <small>(€{{ total.toFixed(2) }})</small>
          </div>
          <div v-else>Een ogenblik...</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
var dateFormat = require("dateformat");
import axios from "axios";
export default {
  data () {
    return {
      submitting: false,
      naam: "",
      telefoon: "",
      email: "",
      afhaalTijd: "",
      afhaalDatum: dateFormat(new Date(), "yyyy-mm-dd"),
      afhaalLocatie: "",
      opmerking: "",
      disableDate: false
    };
  },
  mounted () {
    this.afhaalLocatie = this.gekozenType.locaties[0];
    this.afhaalTijd = this.gekozenType.afhaaltijden[0];
    this.gekozenTypeCheck();
  },
  computed: {
    gekozenType () {
      return this.$store.state.gekozenType;
    }
  },
  watch: {
    gekozenType () {
      this.gekozenTypeCheck();
    }
  },
  methods: {
    gekozenTypeCheck () {
      console.log(this.gekozenType);
      if (this.gekozenType.afhaaltijd_vastzetten) {
        this.disableDate = true;
        this.afhaalDatum = dateFormat(
          new Date(this.gekozenType.afhaaltijd_vastzetten_datum),
          "yyyy-mm-dd"
        );
      }
    },
    async checkout () {
      if (!this.naam || !this.email) {
        alert("Naam en email zijn verplicht");
        return;
      }
      this.submitting = true;
      let data = await axios.post(`/.netlify/functions/payment`, {
        cart: this.$store.state.cart,
        customer: {
          naam: this.naam,
          telefoon: this.telefoon,
          email: this.email
        },
        opmerking: this.opmerking,
        afhaalDatum: this.afhaalDatum,
        afhaalTijd: this.afhaalTijd,
        afhaalLocatie: this.afhaalLocatie,
        type: this.gekozenType.typeFileName
          .replace("./", "")
          .replace(".json", "")
      });

      if (data) {
        window.location.href = data.data;
      } else {
        this.submitting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: 8rem;
  overflow: scroll;
  background: white;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 0;
  font-size: 2rem;
  font-family: inherit;
}
.ml-auto {
  margin-left: auto;
}
h2 {
  margin-bottom: 2rem;
}
.checkout-info {
  display: flex;
  align-items: center;
  color: grey;
  img {
    margin-right: 1rem;
  }
}
.totaal {
  font-size: 120%;
  border-top: 1px dotted lightgrey;
  margin-top: 2rem;
  padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 1rem;
}
.locatie {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #333;
}
h3 {
  margin-bottom: 1rem;
}
.locatie-item {
  padding: 0.3rem 0rem;
  margin-bottom: 0.8rem;
}
.cart-product {
  padding: 0.3rem 0rem;
}
.pickup-time {
  padding-left: 1.5rem;
  padding-top: 0.4rem;
}
.pickup-time--item {
  padding: 0.3rem 0rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.input-item {
  margin-bottom: 1rem;
}

.btn-cart {
  margin-top: 2rem;
}

h3 {
  font-weight: normal;
  padding: 1rem;
  font-size: 1rem;
  background: rgb(243, 243, 243);
}
</style>
